import { AppBar, Box, Button, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PartnerManager from '../../../core/utils/PartnerManager';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import ContextService from '../../../services/ContextService';
import { IContextService } from '../../../services/IContextService';
import Store from '../../../core/models/Store';

declare var $: any;

const Header = (): JSX.Element => {
  const context: IContextService = useContext(ContextService);
  const navigate: NavigateFunction = useNavigate();
  const storeData: Store = PartnerManager.shared().stores[0];
  const logo = PartnerManager.shared().partner?.theme?.logoUrl;
  const toggleLeftBar = (): void => {
    $("#leftBar").toggleClass("showBar");
    $(".showBar").toggleClass("open");
  }

  const LogOut = (): void => {
    PartnerManager.shared().doLogout();
    context.setAccessTokenValid(false);
    context.setPanel("panel1");
    navigate("/");
  }

  return (
    <>
      <AppBar className='header_Bg' position="static">
        <Toolbar variant="dense">
          <IconButton edge="start" onClick={toggleLeftBar} className="toggleLeftBar" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>

          <Grid container>
            <Box className='leftBlock'>

              <Box className='LogoPart'>
                <Box>
                  {logo ? <img src={logo} /> :
                   <img src='../assets/images/header/logo.svg' />}
                </Box>
                <div className='userNameHeader' >
                  <Typography variant="h4">
                    {storeData.name}
                  </Typography>
                </div>
              </Box>

              <Box className='logRight'>
                <img onClick={LogOut} className='usr' src='../assets/images/header/logout.svg' />
              </Box>

            </Box>
          </Grid>
        </Toolbar>

      </AppBar>

    </>
  );
};

export default Header;