import TextUtils from '../utils/TextUtils';

export enum PartnerAppThemeType {
  CATEGORIES = "CATEGORIES",
  EFFECTS = "EFFECTS",
  UNKNOWN = "UNKNOWN",
  EFFECTS1 = "EFFECTS1",
  EFFECTS2 = "EFFECTS2",
  EFFECTS_V2="EFFECTS_V2",
}

export type PartnerAppTheme = {
  id? : number | null | undefined,
  themeId? : number | null | undefined,
  displayName?: string | null,
  name: PartnerAppThemeType,
  logoUrl?: string | null,
  faviconUrl?: string | null,
  logoFileId?: string | null,
  faviconFileId?: string | null,
  backgroundColor?: string | null,
  pageHighlightColor?: string | null,
  buttonColor?: string | null,
  buttonTextColor?: string | null
}

export default class PartnerTheme {
    
    private mId: number;
    public get id() {
      return this.mId;
    }

    private mName: string;
    public get name() {
      return this.mName;
    }

    private mThemeType: PartnerAppThemeType;
    public get themeType() {
      return this.mThemeType;
    }

    private mLogoUrl: string;
    public get logoUrl() {
      return this.mLogoUrl;
    }

    private mFavIconUrl: string;
    public get favIconUrl() {
      return this.mFavIconUrl;
    }

    private mBackgroundColor: string;
    public get backgroundColor() {
      return this.mBackgroundColor;
    }

    private mPageHighlightColor: string;
    public get pageHighlightColor() {
      return this.mPageHighlightColor;
    }

    private mButtonColor: string;
    public get buttonColor() {
      return this.mButtonColor;
    }

    private mButtonTextColor: string;
    public get buttonTextColor() {
      return this.mButtonTextColor;
    }

    public isThemeConfigured(): boolean {
      if(TextUtils.isEmpty(this.mName) ||
      TextUtils.isEmpty(this.mLogoUrl) ||
      TextUtils.isEmpty(this.mFavIconUrl) ||
      TextUtils.isEmpty(this.mBackgroundColor) ||
      TextUtils.isEmpty(this.mPageHighlightColor) ||
      TextUtils.isEmpty(this.mButtonColor) ||
      TextUtils.isEmpty(this.mButtonTextColor)) {
        return false
      }
      return true
    }

    public isCategoryTheme(): boolean {
      if(this.isThemeConfigured() && this.mName === PartnerAppThemeType.CATEGORIES) {
        return true
      }
      return false
    }

    public isEffectTheme(): boolean {
      if(this.isThemeConfigured() && this.mName !== PartnerAppThemeType.CATEGORIES) {
        return true
      }
      return false
    }

    public constructor(theme: PartnerAppTheme) {
        this.mId = theme.id;
        this.mName = theme.name;
        this.mThemeType = theme.name as PartnerAppThemeType
        this.mLogoUrl = theme.logoUrl;
        this.mFavIconUrl = theme.faviconUrl;
        this.mBackgroundColor = theme.backgroundColor;
        this.mPageHighlightColor = theme.pageHighlightColor;
        this.mButtonColor = theme.buttonColor;
        this.mButtonTextColor = theme.buttonTextColor;
    }

    public static themesList(): PartnerTheme[] {
      let arrOfThemes: PartnerTheme[] = []
      const categoryTheme = this.defaultPartnerTheme(PartnerAppThemeType.CATEGORIES)
      const effectV2Theme = this.defaultPartnerTheme(PartnerAppThemeType.EFFECTS_V2)
      if(categoryTheme) {
        arrOfThemes.push(categoryTheme)
      }
      if(effectV2Theme) {
        arrOfThemes.push(effectV2Theme)
      }
      return arrOfThemes
    }

    public static getThemeDisplayName(themeType: PartnerAppThemeType): string {
      let displayName = ""
      switch (themeType) {
        case PartnerAppThemeType.CATEGORIES:
            displayName = "Categories Theme"
            break;
        case PartnerAppThemeType.EFFECTS_V2:
            displayName = "Effects V2 Theme"
            break;
        case PartnerAppThemeType.EFFECTS:
            displayName = "Effects Theme"
            break;
        case PartnerAppThemeType.EFFECTS1:
          displayName = "Effects 1 Theme"
          break;
        case PartnerAppThemeType.EFFECTS2:
          displayName = "Effects 2 Theme"
          break;
        default:
          break;
      }
      return displayName
    }

    /* default theme information */
    public static defaultPartnerTheme(themeType: PartnerAppThemeType): PartnerTheme|undefined {
      let themeObj: PartnerAppTheme = undefined
      const themeDisplayName = this.getThemeDisplayName(themeType)
      switch (themeType) {
        case PartnerAppThemeType.CATEGORIES:
            themeObj = {
              name: themeType,
              displayName: themeDisplayName,
              logoUrl: null,
              faviconUrl: null,
              backgroundColor: '#FFFFFF',
              pageHighlightColor: '#000000',
              buttonColor: '#000000',
              buttonTextColor: '#FFFFFF',
            }
            break;
        case PartnerAppThemeType.EFFECTS_V2:
            themeObj = {
              name: themeType,
              displayName: themeDisplayName,
              logoUrl: null,
              faviconUrl: null,
              backgroundColor: '#F6F0E4',
              pageHighlightColor: '#000000',
              buttonColor: '#3A2D25',
              buttonTextColor: '#FFFFFF',
            }
            break;
        case PartnerAppThemeType.EFFECTS:
            themeObj = {
              name: themeType,
              displayName: themeDisplayName,
              logoUrl: null,
              faviconUrl: null,
              backgroundColor: '#000000',
              pageHighlightColor: '#FFFFFF',
              buttonColor: '#00BC6F',
              buttonTextColor: '#FFFFFF',
            }
            break;
        default:
            break;
      }

      let defaultTheme = (themeObj) ? new PartnerTheme(themeObj) : undefined
      return defaultTheme
    }

    public isCategoryBased(): boolean {
      if(this.themeType === PartnerAppThemeType.CATEGORIES) {
        return true
      }
      return false
    }

    public isEffectBased(): boolean {
      if(this.themeType !== PartnerAppThemeType.CATEGORIES) {
        return true
      }
      return false
    }
}