import WebServiceUtils from "../core/webservice/WebServiceUtils";
import { AuthResponse } from '../core/models/AuthResponse';
import SessionManager from '../core/utils/SessionManager';
import TextUtils from "../core/utils/TextUtils";
import { ApiError } from "../core/webservice/ApiError";
import Strings from '../core/utils/Strings';

export default class AuthService {

    public static async generateJaneRootsToken(clientId: string|null|undefined, clientSecretKey: string|null|undefined) {

        if(TextUtils.isEmpty(clientId) || TextUtils.isEmpty(clientSecretKey)) {
            return Promise.reject(
                new ApiError(400, Strings.MISSING_CLIENT_ID_ERROR)
            );
        }

        const params = new URLSearchParams()
        params.append('grant_type', 'client_credentials')
        params.append('client_id', clientId)
        params.append('client_secret', clientSecretKey)

        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'accept': 'application/json'
            }
          }

        // https://dev-loq8-checkout.digcite.com/phoenix/token
        const apiUrl = process.env.REACT_APP_JANE_ROOTS_BASE_URL + '/token';
        const response = await WebServiceUtils.post(
            params,
            config,
            apiUrl
        );

        if (response.success) {
            try {
                if (response.data) {
                    let authResponse: AuthResponse = response.data as AuthResponse;
                    SessionManager.shared().saveJaneAccessToken(authResponse.access_token);
                    return Promise.resolve(authResponse);
                }
            } catch (error) { }
        }
        return WebServiceUtils.handleNetworkError(response);
    }

    public static async sendAuthCodeByEmail(email: string) {

        if(TextUtils.isEmpty(email)) {
            return Promise.reject(
                new ApiError(400, Strings.MISSING_SEND_OTP_PARAMS_ERROR)
            );
        }

        const params = new URLSearchParams()
        params.append('restore_type', 'email')
        params.append('email_account', email)
        params.append('client_id', 'pnws')
        params.append('user_role', 'JANE_MERCHANT') // Merchant

        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const apiUrl = process.env.REACT_APP_PARTNER_BACKEND_BASE_URL + '/walletOauth2/restore';
        const response = await WebServiceUtils.post(
            params,
            config,
            apiUrl
        );
    
        if (response.success) {
          const httpStatus = (response.response && response.response.status) ? response.response.status : 200;
          try {
            let isOtpSent = false
            if (response.data && httpStatus === 201) {
                isOtpSent = true
            }
            return Promise.resolve(isOtpSent);
          } catch (error) { }
        }
        return WebServiceUtils.handleNetworkError(response);
    }

    public static async verifyAuthCodeByEmail(email: string, otp: string) {

        if(TextUtils.isEmpty(email) || TextUtils.isEmpty(otp)) {
            return Promise.reject(
                new ApiError(400, Strings.MISSING_VERIFY_OTP_PARAMS_ERROR)
            );
        }

        const params = new URLSearchParams()
        const code = email+'|'+otp;
        params.append('code', code)
        params.append('grant_type', 'authorization_code')
        params.append('client_id', 'pnws')

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            }

        const apiUrl = process.env.REACT_APP_PARTNER_BACKEND_BASE_URL + '/walletOauth2/token';
        const response = await WebServiceUtils.post(
            params,
            config,
            apiUrl
        );
        if (response.success) {
            try {
                if (
                    response.data
                ) {
                    SessionManager.shared().saveOnboardingTokenDetails(response.data);
                    return Promise.resolve(response.data);
                }
            } catch (error) { }
        }
        return WebServiceUtils.handleNetworkError(response);
  }


  public static async refreshToken() {

    const params = new URLSearchParams()
    const refreshToken = SessionManager.shared().refreshToken;
    
    params.append('refresh_token', refreshToken)
    params.append('grant_type', 'refresh_token')
    params.append('client_id', 'pnws')

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        }

    const apiUrl = process.env.REACT_APP_PARTNER_BACKEND_BASE_URL + '/walletOauth2/token';
    const response = await WebServiceUtils.post(
        params,
        config,
        apiUrl
    );

    if (response.success) {
        try {
        if (
            response.data
        ) {
            return Promise.resolve(response.data);
        }
        } catch (error) { }
    }
    return WebServiceUtils.handleNetworkError(response);
}

}
