import React, { Component } from "react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PartnerService from "../../../../services/PartnerService";
import { ApiError } from "../../../../core/webservice/ApiError";
import { Box, Typography } from "@mui/material";
interface Iprops {
  logo: { name?: string },
  setLogo:  React.Dispatch<React.SetStateAction<{ name?: string }>>,
  picPath: string,
  setPicPath: React.Dispatch<React.SetStateAction<string>>,
  setLogoFileId: React.Dispatch<React.SetStateAction<string>>,
}
export class UploadLogo extends Component<Iprops> {

  state = {
    profileImg: "../assets/images/drag.svg",
    flag: false,
  };

  imageHandler = (e: any):void => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ profileImg: reader.result, flag: true });
      }
    };
    this.props.setLogo({ name: "" });
    this.props.setPicPath("");
    if (e.target.files[0] != undefined) {
      this.props.setLogo(e.target.files[0]);
      reader.readAsDataURL(e.target.files[0]);
      PartnerService.uploadFile(e.target.files[0])
        .then((fileId: string) => {
          this.props.setLogoFileId(fileId);
        })
        .catch((error: ApiError) => {
          // console.log('error code:' + error.errorCode)
          // console.log('error message:' + error.message)
        });
    } else {
      this.props.setLogo({ name: "" });
    }
  };

  delete = (e: any):void => {
    this.setState({ profileImg: "../assets/images/drag.svg", flag: false });
    this.props.setLogo({ name: "" });
    this.props.setPicPath("");
  };

  render() {
    const { profileImg } = this.state;
    return (
      
      <div className="uploadLogo_main">
                  

        <div className={this.state.flag?"img-holder-active":"img-holder"}>
          {(this.state.flag || this.props.picPath != "") && <DeleteOutlineIcon onClick={this.delete} />}
          <img src={this.props.picPath == "" ? profileImg : this.props.picPath} alt="" id="img" className="img" />

        </div>
        <input
          type="file"
          accept=".png , .jpg"
          name="image-upload"
          id="input"
          value=""
          onChange={this.imageHandler}
        />
        <div className="label space_btn_logo">
          <label className="image-upload" htmlFor="input">       
            {(this.props.logo.name == "" && this.props.picPath == "") ? <span>Upload</span> : <span>Upload</span>}

          </label>
        </div>
      </div>
    );
  }
}

export default UploadLogo;
