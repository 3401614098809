import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import PartnerManager from "../../../core/utils/PartnerManager";
import ContextService from "../../../services/ContextService";
import { IContextService } from "../../../services/IContextService";
import { phoneNoFormat } from "../../../services/Constant";
import Store from "../../../core/models/Store";
import Partner from '../../../core/models/Partner';
import UserPool from "./userPool/UserPool";
import Strings from "../../../core/utils/Strings";
import PartnerService from "../../../services/PartnerService";
import { ApiError } from '../../../core/webservice/ApiError';
import TextUtils from '../../../core/utils/TextUtils';

interface ILinkJaneAccountProps {
  onConfirmClicked(): void
}

const LinkJaneAccount = (props: ILinkJaneAccountProps): JSX.Element => {

  const partner: Partner = PartnerManager.shared().partner;
  const storeData: Store = PartnerManager.shared().stores[0];
  const [stepCompleted, setStepCompleted] = useState<boolean>(!(PartnerManager.shared().partner.isUserPoolDetailsEmpty()));
  const context: IContextService = useContext(ContextService);
  const [region, setRegion] = useState(partner.userPool.region)
  const [userPoolId, setUserPoolId] = useState(partner.userPool.userPoolId)
  const [appClientId, setAppClientId] = useState(partner.userPool.appClientId)
  const [errorRegion, setErrorRegion] = useState(undefined)
  const [errorUserPoolId, setErrorUserPoolId] = useState(undefined)
  const [errorAppClientId, setErrorAppClientId] = useState(undefined)

  useEffect(() => {
    
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      context.setPanel(newExpanded ? panel : false);
    };

  const handleNextClicked = (): void => {
    addOrUpdateUserPoolDetails()
  };

  const addOrUpdateUserPoolDetails = () => {
    setErrorRegion(undefined)
    setErrorUserPoolId(undefined)
    setErrorAppClientId(undefined)
    if(TextUtils.isEmpty(region) || TextUtils.isEmpty(userPoolId) || TextUtils.isEmpty(appClientId)) {
      if(TextUtils.isEmpty(region)) {
        setErrorRegion('Field is empty')
      }
      if(TextUtils.isEmpty(userPoolId)) {
        setErrorUserPoolId('Field is empty')
      }
      if(TextUtils.isEmpty(appClientId)) {
        setErrorAppClientId('Field is empty')
      }
      return
    }
    PartnerService.saveUserPoolDetails(TextUtils.trimString(region),TextUtils.trimString(userPoolId),TextUtils.trimString(appClientId))
      .then((partner: Partner) => {
        moveToNextStep()
      })
      .catch((error: ApiError) => {

      })
  }

  const moveToNextStep = () => {
    props.onConfirmClicked()
    setStepCompleted(true);
    context.setPanel("panel2");
  }

  return (
    <>
      <Accordion
        className="accordion_border border_none"
        expanded={context.panel === "panel1"}
        onChange={handleChange("panel1")}
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="reduce_height"
        >
          <Typography className="acc_header">
            {stepCompleted && (
              <img
                src="../assets/images/main/check.png"
                className="iconCheck"
              />
            )}
            {Strings.LINK_JANE_ACCOUNT}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container style={{ paddingLeft: stepCompleted && "20px" }}>
            <Grid container item md={12} spacing={0}>
              <Grid className="acc_setup_main" container maxWidth="lg">
                <Box className="acc_setup_head">
                  <Typography variant="h3">Jane Credentials</Typography>
                </Box>
              </Grid>

              <Grid container className="acc_credentials">
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Client Name</Typography>
                </Grid>
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Client Key</Typography>
                </Grid>
              </Grid>

              <Grid container className="acc_credentials_sub">
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3"> {partner.clientId}</Typography>
                </Grid>
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3">
                    {partner.clientSecret}
                  </Typography>
                </Grid>
              </Grid>

              <Divider
                style={{ width: "100%" }}
                className="divider"
                sx={{ mt: 2.3, mb: 3 }}
              />
              <Box className="acc_setup_head">
                <Typography variant="h3">Basic Info</Typography>
              </Box>

              <Grid container className="acc_credentials">
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Store Name</Typography>
                </Grid>
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Phone</Typography>
                </Grid>
              </Grid>

              <Grid container className="acc_credentials_sub">
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3"> {storeData.name}</Typography>
                </Grid>
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3">
                    {phoneNoFormat(storeData.phone)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="acc_credentials">
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Address</Typography>
                </Grid>
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Google Maps Url</Typography>
                </Grid>
              </Grid>
              <Grid container className="acc_credentials_sub">
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3"> {storeData.fullAddress}</Typography>
                </Grid>
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3">
                    {(storeData.googleMapsUrl) ? storeData.googleMapsUrl : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="acc_credentials">
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Store Type</Typography>
                </Grid>
                <Grid className="acc_cred_grid" item md={6}>
                <Typography variant="h3"> Medical/Recreational</Typography>
                </Grid>
              </Grid>

              <Grid container className="acc_credentials_sub">
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3">Dispensary</Typography>
                </Grid>
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  {storeData.isMedical && storeData.isRecreational ? (
                    "MEDICAL/RECREATIONAL"
                  ) : storeData.isMedical ? (
                    <Typography variant="h3">Medical only</Typography>
                  ) : (
                    <Typography variant="h3">Recreational Only</Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container className="acc_credentials">
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Description</Typography>
                </Grid>
                <Grid className="acc_cred_grid" item md={6}>
                  <Typography variant="h3">Delivery</Typography>
                </Grid>
              </Grid>
              <Grid container className="acc_credentials_sub">
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3">
                    {storeData.description || "No Description"}
                  </Typography>
                </Grid>
                <Grid item className="acc_cred_ans acc_cust_head" md={6}>
                  <Typography variant="h3">
                    {storeData.isDelivery ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 8 }}
          />

          <UserPool
            region={region}
            userPoolId={userPoolId}
            appClientId={appClientId}
            errorRegion={errorRegion}
            errorUserPoolId={errorUserPoolId}
            errorAppClientId={errorAppClientId}
            onChangeRegion={setRegion}
            onChangeUserPoolId={setUserPoolId}
            onChangeAppClientId={setAppClientId}
          />

<Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 8 }}
          />


          <Grid textAlign="left" item md={12}>
            <Button
              className="setup_btn account-setup"
              sx={{ mt: 2, mb: 2 }}
              variant="text"
              onClick={handleNextClicked}
            >
                {Strings.NEXT_BUTTON_TITLE}
              <Box className="arrowBox">
                <img
                  className="arrowbtn"
                  src="../assets/images/main/arrow.png"
                />
              </Box>
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default LinkJaneAccount;
