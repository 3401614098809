import React from 'react';
import { Box, Grid, Typography, TextField} from "@mui/material";
import { styled } from "@mui/material/styles";

interface IProps {
  region: string,
  userPoolId: string,
  appClientId: string,
  errorRegion: string,
  errorUserPoolId: string,
  errorAppClientId: string,
  onChangeRegion(sRegion: string): void;
  onChangeUserPoolId(sUserPoolId: string): void;
  onChangeAppClientId(sAppClientId: string): void;
}

const UserPool = (props: IProps): JSX.Element => {

    const CssTextField = styled(TextField)({
        "& label.Mui-focused": {
          color: "#d7d2da",
        },
    
        "& .MuiInput-underline:after": {
          borderBottomColor: "#d7d2da",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#d7d2da",
          },
          "&:hover fieldset": {
            borderColor: "#d7d2da",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#d7d2da",
          },
        },
      });

    return (
        <Grid container style={{ paddingLeft: "20px"}} sx ={{mt:5}}>
        <Grid container item md={12} spacing={0}>
              <Grid className="acc_setup_main" container maxWidth="lg">
                <Box className="acc_setup_head write_icon">
                  <Typography variant="h3">User Pool Credentials <Box className="arrowBox">
                <img
                
                  src="../assets/images/main/writeBig.png"
                />
              </Box></Typography>
                </Box>
              </Grid>

              <Grid className="alertBox" sx={{ mt: 2, mb: 4 }} item md={6}>
              <Typography>             
                <p>
                We need user pool credentials for your Jane Account. If you don’t have those, please reach out to us. 
                </p>
              </Typography>
            </Grid>

              <Grid  md={12} container>             
              <Box>
                <TextField
                  error={(props.errorRegion)?true:false}                       
                  fullWidth                 
                  placeholder="region"
                  value={props.region}
                  helperText={(props.errorRegion)?props.errorRegion:''}
                  onChange={(e) => {
                    props.onChangeRegion(e.target.value)
                  }}
                  sx={{
                    width: "400px"
                  }}         
                  variant="standard"
                />
                </Box>
              </Grid>

              <Grid md={12} container  sx ={{mt:4}}>             
              <Box>
                <TextField
                  error={(props.errorUserPoolId)?true:false}                         
                  fullWidth                 
                  placeholder="user_pool_id"
                  value={props.userPoolId}
                  helperText={(props.errorUserPoolId)?props.errorUserPoolId:''}
                  onChange={(e) => {
                    props.onChangeUserPoolId(e.target.value)
                  }}
                  sx={{
                    width: "400px"
                  }}       
                  variant="standard"
                />
                </Box>
              </Grid>

              <Grid  md={12} container  sx ={{mt:4}}>             
              <Box>
                <TextField
                  error={(props.errorAppClientId)?true:false}                         
                  fullWidth                 
                  placeholder="app_client_id"
                  value={props.appClientId}
                  helperText={(props.errorAppClientId)?props.errorAppClientId:''}
                  onChange={(e) => {
                    props.onChangeAppClientId(e.target.value)
                  }}
                  sx={{
                    width: "400px"
                  }}              
                  variant="standard"
                />
                </Box>
              </Grid>
</Grid>
</Grid>

    );
};

export default UserPool;