import React, { useContext, useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import Partner, { PartnerSocialMedia } from "../../../core/models/Partner";
import PartnerService from "../../../services/PartnerService";
import { ApiError } from "../../../core/webservice/ApiError";
import { IContextService } from "../../../services/IContextService";
import ContextService from "../../../services/ContextService";

import { styled } from "@mui/material/styles";
import PartnerManager from "../../../core/utils/PartnerManager";
import Strings from '../../../core/utils/Strings';

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#d7d2da",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#d7d2da",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#d7d2da",
    },
    "&:hover fieldset": {
      borderColor: "#d7d2da",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#d7d2da",
    },
  },
});

const SocialMediaAccount = (): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(true);
  const [tickIcon, setTickIcon] = useState<boolean>(false);
  const [instagram, setInstagram] = useState<string>("");
  const [facebook, setFacebook] = useState<string>("");
  const [youtube, setYoutube] = useState<string>("");
  const [snapchat, setSnapchat] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [pinterest, setPinterest] = useState<string>("");
  const context: IContextService = useContext(ContextService);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      context.setPanel(newExpanded ? panel : false);
    };
  useEffect(() => {
    if (PartnerManager.shared().partner) {
      const data = PartnerManager.shared().partner;
      setTickIcon(data.isAnySocialMediaInfoAvailable());
      setInstagram(data.instagramUrl);
      setFacebook(data.facebookUrl);
      setYoutube(data.youtubeUrl);
      setSnapchat(data.snapchatUrl);
      setTwitter(data.twitterUrl);
      setPinterest(data.pinterestUrl);
    }
  }, []);

  const SubmitBtn = (): void => {
    const socialMedia: PartnerSocialMedia = {
      instagramUrl: instagram,
      fbUrl: facebook,
      youTubUrl: youtube,
      twitterUrl: twitter,
      snapchatUrl: snapchat,
      pinterestUrl: pinterest,
    };
    PartnerService.saveSocialMedia(socialMedia)
      .then((result: Partner) => {
        if (result) {
          PartnerManager.shared().setIsSocialMediaUpdated(true)
          PartnerManager.shared().partner.setupPartnerAppState()
          setTimeout(() => {
            setTickIcon(true);
            context.setPanel("panel6");
            context.setHeader({ length: 83.3, index: 5 });
          },100)
        }
      })
      .catch((error: ApiError) => {
        // console.log("error code:" + error.errorCode);
        // console.log("error message:" + error.message);
      });
  };

  return (
    <>
      <Accordion
        className="accordion_border border_none"
        expanded={context.panel === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          sx={{ mt: 2 }}
          expandIcon={<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="reduce_height"
        >
          <Typography className="acc_header">
            {tickIcon && (
              <img
                src="../assets/images/main/check.png"
                className="iconCheck"
              />
            )}
           {Strings.CONNECT_SOCIAL_MEDIA}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="socialMedia">
          <Grid
            container
            item
            md={10}
            spacing={0}
            style={{ paddingLeft: tickIcon && "20px" }}
          >
            <Grid className="alertBox" sx={{ mt: 2, mb: 4 }} item md={6}>
              <Typography>
                <h3>Optional</h3>
                <p>
                  Adding Social Media links is optional. Click Next to skip. You
                  can always add them later.
                </p>
              </Typography>
            </Grid>

            <Grid container spacing={3}>
              <Grid textAlign="left" item md={6}>
                <CssTextField
                  className="socialLink"
                  id="outlined-helperText"
                  fullWidth
                  label="Instagram"
                  value={instagram}
                  onChange={(e) => {
                    setInstagram(e.target.value);
                  }}
                  helperText="Paste the URL to your Instragram page"
                  variant="standard"
                />
              </Grid>

              <Grid textAlign="left" item md={6}>
                <CssTextField
                  id="outlined-helperText"
                  className="socialLink"
                  fullWidth
                  label="Twitter"
                  value={twitter}
                  onChange={(e) => {
                    setTwitter(e.target.value);
                  }}
                  helperText="Paste the URL to your Twitter page"
                  variant="standard"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid textAlign="left" item md={6}>
                <CssTextField
                  id="outlined-helperText"
                  className="socialLink"
                  fullWidth
                  label="Facebook"
                  value={facebook}
                  onChange={(e) => {
                    setFacebook(e.target.value);
                  }}
                  helperText="Paste the URL to your Facebook page"
                  variant="standard"
                />
              </Grid>

              <Grid textAlign="left" item md={6}>
                <CssTextField
                  id="outlined-helperText"
                  className="socialLink"
                  fullWidth
                  label="Snapchat"
                  value={snapchat}
                  onChange={(e) => {
                    setSnapchat(e.target.value);
                  }}
                  helperText="Paste the URL to your Snapchat page"
                  variant="standard"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid textAlign="left" item md={6}>
                <CssTextField
                  id="outlined-helperText"
                  className="socialLink"
                  fullWidth
                  label="YouTube"
                  value={youtube}
                  onChange={(e) => {
                    setYoutube(e.target.value);
                  }}
                  variant="standard"
                  helperText="Paste the URL to your YouTube page"
                />
              </Grid>

              <Grid textAlign="left" item md={6}>
                <CssTextField
                  id="outlined-helperText"
                  className="socialLink"
                  fullWidth
                  label="Pinterest"
                  value={pinterest}
                  onChange={(e) => {
                    setPinterest(e.target.value);
                  }}
                  helperText="Paste the URL to your Pinterest page"
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 5 }}
          />
          <Grid textAlign="left" item md={12}>
            <Button
              className="setup_btn account-setup"
              sx={{ mt: 3, mb: 3 }}
              variant="text"
              onClick={SubmitBtn}
            >
              Next{" "}
              <Box className="arrowBox">
                {" "}
                <img
                  className="arrowbtn"
                  src="../assets/images/main/arrow.png"
                />
              </Box>
            </Button>
          </Grid> */}
          <Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 8 }}
          />
          <Grid textAlign="left" item md={12}>
            <Button
              className="setup_btn account-setup"
              sx={{ mt: 2, mb: 2 }}
              variant="text"
              onClick={SubmitBtn}
            >
              {Strings.NEXT_BUTTON_TITLE}
              <Box className="arrowBox">
                <img
                  className="arrowbtn"
                  src="../assets/images/main/arrow.png"
                />
              </Box>
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default SocialMediaAccount;
