import React, { Component } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PartnerService from '../../../../services/PartnerService';
import { ApiError } from '../../../../core/webservice/ApiError';
import { Box, Typography } from '@mui/material';
interface IProps {
    favicon: { name?: string },
    setFavicon: React.Dispatch<React.SetStateAction<{ name?: string }>>,
    favPicPath: string,
    setFavPicPath: React.Dispatch<React.SetStateAction<string>>,
    setFavLogoFileId: React.Dispatch<React.SetStateAction<string>>,
}
export class UploadFavicon extends Component<IProps>{
    state = {
        profileImg_Fav: '../assets/images/drag_icon.svg',
        flag: false,
    }
    imageHandler_Fav = (e: any): void => {
        const reader1 = new FileReader();
        reader1.onload = () => {
            if (reader1.readyState === 2) {
                this.setState({ profileImg_Fav: reader1.result, flag: true })
            }
        }
        this.props.setFavicon({ name: "" });
        this.props.setFavPicPath("")
        if (e.target.files[0] != undefined) {
            this.props.setFavicon(e.target.files[0]);
            reader1.readAsDataURL(e.target.files[0]);
            PartnerService.uploadFile(e.target.files[0])
                .then((fileId: string) => {
                    this.props.setFavLogoFileId(fileId);
                })
                .catch((error: ApiError) => {
                    // console.log('error code:' + error.errorCode)
                    // console.log('error message:' + error.message)
                });
        }
        else { this.props.setFavicon({ name: "" }); }
    };

    delete = (e: any): void => {
        this.setState({ profileImg_Fav: "../assets/images/drag_icon.svg", flag: false });
        this.props.setFavicon({ name: "" });
        this.props.setFavPicPath("")
    };

    render() {
        const { profileImg_Fav } = this.state;
        return (
            <div className='uploadLogo_main'>
              
                <div className={this.state.flag ? "img-holder-active" : "img-holder"}>
                    {(this.state.flag || this.props.favPicPath != "") && <DeleteOutlineIcon onClick={this.delete} />}
                    <img src={this.props.favPicPath == "" ? profileImg_Fav : this.props.favPicPath} alt="" id="img1" className="img1" />
                </div>
                <input type="file" accept=".ico" value=""
                    name="image-upload1" id="input1" onChange={this.imageHandler_Fav} />
               <div className="label space_btn_logo">
                    <label className="image-upload" htmlFor="input1">
                       
                        {(this.props.favicon.name == "" && this.props.favPicPath == "") ? <span>Upload</span> : <span>Upload</span>}
                    </label>
                </div>
            </div>

        );
    }
}

export default UploadFavicon;
