import { Box} from '@mui/material';
import React, { useState } from 'react';
interface IProps{
  buttonColor:string,
  buttonTextColor:string
}
const ButtonColors = (props:IProps) => {
  const {buttonColor,buttonTextColor}=props;
  return (
  <>   
<div className='btnColor' style={{background:buttonColor}}>
<p className='samplebtn' style={{color:buttonTextColor}}>Sample</p>
</div>

{/* 
<Box className='btnColor_Picker'>
<p className='colorPickerTxt_1'>{buttonColor}</p>
<p className='colorPickerTxt_1'>{buttonTextColor}</p>
</Box> */}

</>
  
  )};
export default ButtonColors;