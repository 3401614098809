import React, { useContext, useEffect, useRef, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Category from "../../../core/models/Category";
import { ApiError } from "../../../core/webservice/ApiError";
import PartnerManager from "../../../core/utils/PartnerManager";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import PartnerService from "../../../services/PartnerService";
import ContextService from "../../../services/ContextService";
import { IContextService } from "../../../services/IContextService";

import {  ColDef } from "ag-grid-community";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Effect from "../../../core/models/Effect";
import PartnerTheme from '../../../core/models/PartnerTheme';
import StoreOperationsService from '../../../services/StoreOperationsService';
import Strings from '../../../core/utils/Strings';

import customeIcons from "./customeIcons";

interface IProps {
  pActiveTheme: PartnerTheme
}

const OrganiseEffects = (props: IProps): JSX.Element => {
  const sPartner = PartnerManager.shared().partner
  var objPickFrom: number;
  const [tickIcon, setTickIcon] = useState<boolean>((sPartner.effects && sPartner.effects.length > 0) ? true : false);
  const context: IContextService = useContext(ContextService);
  const gridRef = useRef<AgGridReact>(null);
  const effectList = (PartnerManager.shared().effects) ? PartnerManager.shared().effects : []
  const [data, setData] = useState<Effect[]>(effectList)

  const columnDefs: ColDef[] = [
    { field: "rank", headerName: "ORDER", width: 170, suppressMovable: true },
    { field: "name", headerName: "EFFECT NAME", width: 170, suppressMovable: true },
    {
      field: "productsCount", headerName: "# OF PRODUCTS", suppressMovable: true,
      cellRenderer: customeIcons, width: 270
    },
  ];

  useEffect(() => {
    if (data.length === 0) {
      StoreOperationsService.getEffects()
        .then((effects: Effect[]) => {
          setData(effects)
        })
        .catch((error: ApiError) => {
          // console.log('error code:' + error.errorCode)
          // console.log('error message:' + error.message)
        });
    }
  }, []);
  const DragEvEnd = (node: any): void => {
    let objDropHere = node.overIndex;
    let temp = data[objPickFrom];
    if (objDropHere > objPickFrom) {
      for (var i = objPickFrom; i <= objDropHere; i++) {
        if (i == objDropHere) {
          data[i] = temp;
          gridRef.current!.api.setRowData(data);
        } else {
          data[i] = data[i + 1];
          gridRef.current!.api.setRowData(data);
        }
      }
      for (var t = 0; t < data.length; t++) {
        data[t].rank = t + 1;
        gridRef.current!.api.setRowData(data);
      }
    } else {
      for (var j = objPickFrom; j >= objDropHere; j--) {
        if (j == objDropHere) {
          data[j] = temp;
          gridRef.current!.api.setRowData(data);
        } else {
          data[j] = data[j - 1];
          gridRef.current!.api.setRowData(data);
        }
      }
      for (var t = 0; t < data.length; t++) {
        data[t].rank = t + 1;
        gridRef.current!.api.setRowData(data);
      }
    }
  };
  const DragEvStart = (node: any): void => {
    let objWhichIsShifted = node.node.data;
    objPickFrom = data.findIndex((x) => x.name === objWhichIsShifted.name);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      context.setPanel(newExpanded ? panel : false);
    };


  const saveEffects = (): void => {
    PartnerService.saveEffects(data)
      .then((isSaved: boolean) => {
        if (isSaved) {
          setTickIcon(true);
          context.setPanel("panel5");
          // context.setHeader({ length: 66.64 , index: 4 });
        }
      })
      .catch((error: ApiError) => {
        // console.log("error code:" + error.errorCode);
        // console.log("error message:" + error.message);
      });
  };
  return (
    <>
      <Accordion
        className="accordion_border border_none"
        expanded={context.panel === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          sx={{ mt: 2 }}
          expandIcon={<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="reduce_height"
        >
          <Typography className="acc_header">
            {tickIcon && (
              <img
                className="iconCheck"
                src="../assets/images/main/check.png"
              />
            )}
            4. Organize Effects
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            item
            md={10}
            spacing={0}
            style={{ paddingLeft: tickIcon && "20px" }}
          >
            <Grid className="alertBox" sx={{ mt: 2, mb: 4 }} item md={9}>
              <Typography>
                We’ve automatically pulled your product data from your Jane
                account and applied the following effects to your products:
              </Typography>
            </Grid>

            <Grid container item md={12} sx={{ mt: 2, mb: 4 }} spacing={3}>
              <div className="ag-theme-alpine">
                <AgGridReact
                  rowData={data}
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowDragManaged={true}
                  rowDragEntireRow={true}
                  onRowDragEnd={(node) => DragEvEnd(node)}
                  onRowDragEnter={(node) => DragEvStart(node)}
                // frameworkComponents={{
                //   iconComponent: IconComponent
                // }}
                // components={
                //   {
                //      IconComponent
                //     }

                // }
                ></AgGridReact>
              </div>
            </Grid>
          </Grid>

          <Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 5 }}
          />

          <Grid textAlign="left" item md={12}>
            <Button
              className="setup_btn account-setup"
              variant="text"
              sx={{ mt: 2, mb: 2 }}
              onClick={saveEffects}
            >
              {Strings.NEXT_BUTTON_TITLE}{" "}
              <Box className="arrowBox">
                {" "}
                <img
                  className="arrowbtn"
                  src="../assets/images/main/arrow.png"
                />
              </Box>
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default OrganiseEffects;
