declare var $: any;

export const toggleBox =()=>{
    setTimeout(() => {
      $('.firstLoad').show();
      $('._name').hide();
      $('.btnProgress').hide();
      $('.nameAddress').hide();
      $('.FourLoad').hide();
      $('.progress2').hide();
      $('.successfully_import').hide();
    },500);
  
    setTimeout(() => {
      $('._name').show();
      $('.nameAddress').hide();
      $('.btnProgress').hide();
      $('.FourLoad').hide();
      $('.successfully_import').hide();
      $('.progress2').hide();
    },1000);
  
    setTimeout(() => {
      $('.nameAddress').show();
      $('.btnProgress').hide();
      $('.FourLoad').hide();
      $('._name').hide();
      $('.progress2').hide();
      $('.successfully_import').hide();
    },3000);
  
    setTimeout(() => {
      $('.secondLoad').show();
      $('._name1').hide();
      $('.FourLoad').hide();
      $('.btnProgress').hide();
      $('.nameAddress1').hide();
      $('.progress2').hide();
      $('.successfully_import').hide();
    }, 4000);
  
    setTimeout(() => {
      $('._name1').show();
      $('.nameAddress1').hide();
      $('.btnProgress').hide();
      $('.FourLoad').hide();
      $('.progress2').hide();
      $('.successfully_import').hide();
    },6000);
  
    setTimeout(() => {
      $('.nameAddress1').show();
      $('._name1').hide();
      $('.FourLoad').hide();
      $('.btnProgress').hide();
      $('.progress2').hide();
      $('.successfully_import').hide();
    },8000);
  
    setTimeout(() => {
      $('.thirdLoad').show();
      $('._name2').hide();
      $('.nameAddress2').hide();
      $('.FourLoad').hide();
      $('.btnProgress').hide();
      $('.progress2').show();
      $('.successfully_import').hide();
    }, 10000);
  
    setTimeout(() => {
      $('._name2').show();
      $('.nameAddress2').hide();
      $('.FourLoad').hide();
      $('.btnProgress').hide();
      $('.progress2').show();
      $('.successfully_import').hide();
    },13000);
  
    setTimeout(() => {
      $('.nameAddress2').show();
      $('._name2').hide();
      $('.FourLoad').hide();
      $('.btnProgress').hide();
      $('.progress2').show();
      $('.successfully_import').hide();
    },16000);
  
  
    setTimeout(() => {
      $('.FourLoad').show();
      $('.progress2').show();
      $('.btnProgress').show();
      
     
    },16000);
  
    setTimeout(() => {
      $('.progress2').show();
      $('.btnProgress').show();
     
    },20000);
  
    setTimeout(() => {
      $('.successfully_import').show();
      $('.btnProgress').show();
     
    },16000);
  }  