import React from "react";
import styles from "./HeartManage.module.scss";
import { Grid } from "@mui/material";

const HeartManage = (): JSX.Element => {
  return (
    <div>
      <Grid container className={styles.fullheight}>
        <Grid md={12} className={styles.makingRelative}>
          <div className={styles.centeringImage}>
            <img
              src="../assets/images/whiteHeart.svg"
              className={styles.imageResolution}
            />
          </div>
        </Grid>
        <Grid md={12} className={styles.centeringText}>
          <div className={styles.createCss}>
            CREATE AND MANAGE YOUR ONLINE STORE
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeartManage;
