export default class Strings {
    
  /* Common */
  public static NEXT_BUTTON_TITLE = "SAVE & NEXT";

  /* Start Accordion */
  public static LINK_JANE_ACCOUNT = "1. Link Jane Account";
  public static CHOOSE_THEME = "2. Choose Theme";
  public static SETUP_BRANDING_LOGO = "3. Setup Branding & Logo"; 
  /* Start Sub Head */
  public static YOUR_APP_NAME = "Your App/Display Name"; 
  public static YOUR_LOGO = "Your Logo"; 
  public static FAVICON = "Favicon"; 
  public static PAGE_COLORS = "Page Colors"; 
  public static BUTTON_COLORS = "Button Colors"; 
/*--------------------------------------------*/
  public static PAGE_BACKGROUND_COLOR = "Page Background Color"; 
  public static PRIMARY_TEXT_COLOR = "Primary Text Color"; 
  public static BUTTON_COLOR = "Button Color";
  public static BUTTON_TEXT_COLOR = "Button Text Color";

  /* End Sub Head */

  public static ORGANIZE_CATEGORIES = "4. Organize Categories"; 
  public static CONNECT_SOCIAL_MEDIA = "5. Connect Social Media"; 
  public static CONNECT_DOMAIN = "6. Connect Domain"; 
 /* End Accordion */


  /* Error messages */
  public static DEFAULT_ERROR_MSG = "Something went wrong, Please try again.";
  public static NETWORK_ERROR = "Lost internet connection. Please check and try again.";
  public static FIELD_IS_EMPTY = "Field is empty";
  public static UNAUTHORIZED_ERROR = "unauthorized";
  public static MISSING_CLIENT_ID_ERROR = "Please provide client_id and client_secret.";
  public static BEARER_TOKEN_NOT_AVAILABLE_ERROR = "Please include valid access_token value in the Authorization header field as an HTTP bearer authorization scheme.";
  public static MISSING_SEND_OTP_PARAMS_ERROR = "Please provide email."
  public static MISSING_VERIFY_OTP_PARAMS_ERROR = "Please provide email and otp."
  public static ALGOLIA_NOT_CONFIGURED_ERROR = "Please configure algoliaAppId and algoliaAppKey."
  public static INVALID_FILE_ERROR = "Invalid file extention pleasse select another file."
  public static PARTNER_ALREADY_CREATED_ERROR = "Partner already created."
  public static REQUEST_PARAMS_MISSING = "Request parameters missing."

  private constructor() { }
}
