import React, { useEffect, useState } from 'react';
import './App.css';
import theme from "./theme";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import AccountSetup from './components/accountSetup/AccountSetup';
import { ApiTestingComponent } from './components/api_testing/ApiTestComponent';
import Login from './components/login/Login';
import Otp from './components/otp/Otp';
import AccountCreation from './components/accountCreation/AccountCreation';
import ContextService from "./services/ContextService";
import SessionManager from './core/utils/SessionManager';

const App = (): JSX.Element => {

  const [accessTokenValid, setAccessTokenValid] = useState<boolean>(false);
  const [panel, setPanel] = useState<string | false>("panel1");
  const [header, setHeader] = useState<{ length: number; index: number }>({ length: 0, index: 0 });
  useEffect((): void => {
    if (SessionManager.shared().accessToken) {
      setAccessTokenValid(true);
    } else {
      setAccessTokenValid(false);
    }
  }, [])

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ContextService.Provider value={{
          accessTokenValid, setAccessTokenValid,
          panel, setPanel,
          header, setHeader
        }}>
          <Routes>

            <Route path="/" element={!accessTokenValid ? <Login /> : <Navigate to={"/accountCreation"} />} />

            {/* <Route path="/api" element={<ApiTestingComponent />} /> */}

            <Route path="/otp" element={<Otp />} />
           
            <Route path="/accountCreation" element={accessTokenValid ? <AccountCreation /> : <Navigate to={"/"} />} />

            <Route path="/accountSetup" element={accessTokenValid ? <AccountSetup /> : <Navigate to={"/"} />} />

            <Route path="/*" element={!accessTokenValid ? <Login /> : <Navigate to={"/accountCreation"} />} />

          </Routes>
        </ContextService.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
