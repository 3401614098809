import TextUtils from "./TextUtils";
// import compareVersions from "compare-versions";

export default class GeneralUtils {
  private constructor() { }

  public static makeACall(phoneNum: string | undefined) {
    if (!TextUtils.isEmpty(phoneNum)) {
      window.location.href = "tel:" + phoneNum;
    }
  }

  public static isAndroidOS(): boolean {
    let userAgent = navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  }

  public static isSafariBrowser(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  public static validHostName(enteredDomain: string): string|undefined {
    let finalHostName = undefined
    if(enteredDomain && !TextUtils.isEmpty(enteredDomain)) {
      let domainUrl = enteredDomain
      if(domainUrl.includes('http://') || domainUrl.includes('https://')) {
        domainUrl = domainUrl.replace('http://','')
        domainUrl = domainUrl.replace('https://','')
      }
      const domainRegex = "^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$"
      if(domainUrl.match(domainRegex)) { 
        finalHostName = domainUrl
      }
    }
    // console.log("finalHostName =>"+finalHostName);
    return finalHostName
  }

  /*public static forceAppUpdateRequired(latestVersion: string, latestBuildVersion: string): boolean {
    var storeAppVersion = latestVersion;
    var currentAppV = process.env.REACT_APP_VERSION
      ? process.env.REACT_APP_VERSION
      : "";
    var storeAppBuildVersion = latestBuildVersion;
    var currentAppBuildV = process.env.REACT_APP_BUILD_VERSION
      ? process.env.REACT_APP_BUILD_VERSION
      : "";

    if (
      !TextUtils.isEmpty(storeAppVersion) &&
      !TextUtils.isEmpty(currentAppV)
    ) {
      let versionComparator = compareVersions(currentAppV, storeAppVersion);
      if (versionComparator == -1) {
        return true;
      }
    }

    if (
      !TextUtils.isEmpty(storeAppBuildVersion) &&
      !TextUtils.isEmpty(currentAppBuildV)
    ) {
      let buildComparator = compareVersions(
        currentAppBuildV,
        storeAppBuildVersion
      );
      if (buildComparator == -1) {
        return true;
      }
    }

    return false;
  }*/

}
