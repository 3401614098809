
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PartnerManager from '../../../core/utils/PartnerManager';

type SetupGuideProps = {

};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#308fe8',
    },
  }));

export const SetupGuide: React.FC<SetupGuideProps> = (props: SetupGuideProps) => {

    const progressInfo = PartnerManager.shared().getProgressValueForSite();
  
    const renderSiteProgress = () => {
        return (
          <Box className='centerBlock'
            sx={{
              visibility: (progressInfo.isWebsiteLive) ? 'hidden' : 'visible'
            }}>
             <div className="blankRow">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div> 
            <BorderLinearProgress variant="determinate" value={progressInfo.progress} 
              sx={{ mt: 1.6 }} style={{ float: "left", width: "100%" }} />
            {/* {progressInfo.completedSteps === progressInfo.totalSteps ?
              <Box className='readytogo'> <img src='../assets/images/readytogo.svg' className='iconreadytogo' />
                <p>Ready to Launch</p></Box>
              : <span className='progressCount'>{`${progressInfo.completedSteps}/${progressInfo.totalSteps}`}</span>} */}
          </Box>
        )
    }

    return(
        <Box>
            <Typography variant="h5" align="left" sx={{ mt: 2, mb: 1 }}>
                Setup Guide {" "}
                {!progressInfo.isWebsiteLive && <small style={{marginLeft:"10px"}}>
                    {`${progressInfo.completedSteps}/${progressInfo.totalSteps}`}
                </small>}
            </Typography>
            {renderSiteProgress()}
        </Box>
    )
}