import React, { useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  TextField,
} from "@mui/material";
import AuthService from "../../services/AuthService";
import { ApiError } from "../../core/webservice/ApiError";
import { To, useNavigate } from "react-router-dom";
import { validEmail } from "../../services/Constant";
import { styled } from "@mui/material/styles";

import HeartManage from "../HeartManage/HeartManage";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#d7d2da",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#d7d2da",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#d7d2da",
    },
    "&:hover fieldset": {
      borderColor: "#d7d2da",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#d7d2da",
    },
  },
});

interface NavigateFunction {
  (to: To, options?: { replace?: boolean; state?: string }): void;
  (delta: number): void;
}

const Login = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const navigate: NavigateFunction = useNavigate();
  const [emailError, setEmailError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const EmailsentBtn = (e: any): void => {
    e.preventDefault();
    setLoading(true);
    AuthService.sendAuthCodeByEmail(email)
      .then((isOtpSent: boolean) => {
        if (isOtpSent) {
          navigate("/otp", { state: email });
        }
      })
      .catch((error: ApiError) => {
        setLoading(false);
      });
  };

  const validateEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
    if (!validEmail.test(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const preventFxn = (e: any): void => {
    e.preventDefault();
  };

  return (
    <>
      <Grid container className="fullHeight">
        <Grid md={6}>
          <Grid md={12}>
            <div>
              <img
                src="../assets/images/janeLogo.svg"
                className="logoResolution"
              />
            </div>
          </Grid>
          <div className="containerWidth">
            <Grid md={12}>
              <div>
                <div className="welcome">welcome</div>
              </div>
            </Grid>
            <Grid md={12}>
              <div className="loginPageInput">
                <CssTextField
                  id="custom-css-outlined-input"
                  value={email}
                  onChange={validateEmail}
                  fullWidth
                  placeholder="your email address"
                  variant="standard"
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      EmailsentBtn(ev);
                    }
                  }}
                />
              </div>
              <div className="subTopic">Enter your email</div>
            </Grid>
            <Grid md={12}>
              <div className="buttoncontainer">
                <Button
                  onClick={EmailsentBtn}
                  fullWidth
                  className={
                    emailError
                      ? "unactive_btn_loadingActive"
                      : "active_btn_loadingActive"
                  }
                  variant="contained"
                  disabled={emailError}
                >
                  <p className="loadbtn">
                    {loading && "Please Wait"}
                    {!loading && " Continue"}
                    {loading && (
                      <i className="fa fa-circle-o-notch fa-spin"></i>
                    )}
                  </p>
                </Button>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid md={6}>
          <HeartManage />
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
