import PartnerManager from "../core/utils/PartnerManager";
import SessionManager from "../core/utils/SessionManager";
import AuthService from "./AuthService";
import PartnerService from "./PartnerService";
import StoreOperationsService from "./StoreOperationsService";

export const validEmail: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const url = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

export const generateJaneToken = async (): Promise<boolean> => {
    if (SessionManager.shared().isJaneTokenAvailable()) {
        // janeAccessToken available, no need to login again.
        // console.log('janeAccessToken :' + SessionManager.shared().janeAccessToken)
        return true;
    }
    let clientId = '' // client_key
    let clientSecret = '' // client_secret_key
    if (PartnerManager.shared().isJaneCredentialsAvailable()) {
        clientId = PartnerManager.shared().janeClientCredentials()[0]
        clientSecret = PartnerManager.shared().janeClientCredentials()[1]
    }
    const data = await AuthService.generateJaneRootsToken(clientId, clientSecret)
    if (data) {
        return true;
    } else {
        return false
    }
}

export const CreateStore = async (): Promise<boolean> => {
    try {
        const data = await StoreOperationsService.getStores();
        if (data) {
            return true;
        } else {
            return false
        }
    } catch (error) {
        // console.log(error)
    }
}

export const phoneNoFormat = (data: string): string => {
    if (data && data.length == 10) {
        const front = data.slice(0, 3);
        const back3 = data.slice(3, 6);
        const last4 = data.slice(6, 10);
        return `+1 (${front}) ${back3}-${last4}`
    } else {
        const frontFirst = data.slice(0, 2)
        const front = data.slice(2, 5);
        const back = data.slice(5, 8);
        const last = data.slice(8, 12);
        return `${frontFirst} (${front}) ${back}-${last}`
    }
}


export const getCategoryList = () => {
    try {
        const dataResp = StoreOperationsService.getCategories();
        if (dataResp) {
            return true;
        }
    } catch (error) {
        // console.log(error);
    }
};

export const getEffectsList = () => {
    try {
        const dataResp = StoreOperationsService.getEffects();
        if (dataResp) {
            return true;
        }
    } catch (error) {
        // console.log(error);
    }
};