import Category from './Category';
import Effect from './Effect';
import PartnerTheme from './PartnerTheme';
import TextUtils from '../utils/TextUtils';
import PartnerManager from '../utils/PartnerManager';
import PartnerUserPool from './PartnerUserPool';

export enum PartnerState {
    DRAFT = "DRAFT",
    LIVE = "LIVE",
}

export enum PartnerAppState {
  LIVE = "LIVE",
  SETUP_COMPLETED = "SETUP_COMPLETED",
  SETUP_INCOMPETE = "SETUP_INCOMPETE",
}

export enum ExternalDomainStatus {
  PENDING = "PENDING",
  CONNECTED = "CONNECTED",
  UNKNOWN = "UNKNOWN"
}

export type PartnerSocialMedia = {
  instagramUrl: string | null,
  fbUrl: string | null,
  youTubUrl: string | null,
  twitterUrl: string | null,
  snapchatUrl: string | null,
  pinterestUrl: string | null,
}

export default class Partner {
    private mId: number;
    public get id() {
      return this.mId;
    }

    private mClientId: string;
    public get clientId() {
      return this.mClientId;
    }

    private mClientSecret: string;
    public get clientSecret() {
      return this.mClientSecret;
    }

    private mDomainName: string;
    public get domainName() {
      return this.mDomainName;
    }

    private mExternalDomainName: string;
    public get externalDomainName() {
      return this.mExternalDomainName;
    }

    private mAlgoliaAppId: string;
    public get algoliaAppId() {
      return this.mAlgoliaAppId;
    }

    private mAlgoliaAppKey: string;
    public get algoliaAppKey() {
      return this.mAlgoliaAppKey;
    }

    private mInstagramUrl: string;
    public get instagramUrl() {
      return this.mInstagramUrl;
    }

    private mFacebookUrl: string;
    public get facebookUrl() {
      return this.mFacebookUrl;
    }

    private mYoutubeUrl: string;
    public get youtubeUrl() {
      return this.mYoutubeUrl;
    }

    private mTwitterUrl: string;
    public get twitterUrl() {
      return this.mTwitterUrl;
    }

    private mSnapchatUrl: string;
    public get snapchatUrl() {
      return this.mSnapchatUrl;
    }

    private mPinterestUrl: string;
    public get pinterestUrl() {
      return this.mPinterestUrl;
    }

    private mPartnerState: PartnerState;
    public get partnerState() {
      return this.mPartnerState;
    }

    private mPartnerAppState: PartnerAppState;
    public get partnerAppState() {
      return this.mPartnerAppState;
    }

    private mPartnerTheme: PartnerTheme;
    public get theme() {
      return this.mPartnerTheme;
    }

    private mPartnerCategories: Category[];
    public get categories() {
      return this.mPartnerCategories;
    }

    private mPartnerEffects: Effect[];
    public get effects() {
      return this.mPartnerEffects;
    }

    private mSocialMediaUpdated: boolean;
    public get socialMediaUpdated() {
      return this.mSocialMediaUpdated;
    }

    private mAppName: string;
    public get appName() {
      return this.mAppName;
    }

    private mUserPool: PartnerUserPool | undefined;
    public get userPool() {
      return this.mUserPool;
    }

    private mExternalDomainStatus: ExternalDomainStatus;
    public get externalDomainStatus() {
      return this.mExternalDomainStatus;
    }

    public setTheme(theme: PartnerTheme) {
      this.mPartnerTheme = theme
      this.setupPartnerAppState()
    }

    public setCategories(categories: Category[]) {
      this.mPartnerCategories = categories
      this.setupPartnerAppState()
    }

    public setEffects(effects: Effect[]) {
      this.mPartnerEffects = effects
      this.setupPartnerAppState()
    }

    public constructor(responseJson: any) {
      this.mId = responseJson.id;
      this.mClientId = responseJson.clientId;
      this.mClientSecret = responseJson.clientSecret;
      this.mDomainName = responseJson.domainName;
      this.mExternalDomainName = responseJson.externalDomainName;
      this.mAlgoliaAppId = responseJson.algoliaAppId;
      this.mAlgoliaAppKey = responseJson.algoliaAppKey;
      this.mInstagramUrl = responseJson.instagramUrl;
      this.mFacebookUrl = responseJson.fbUrl;
      this.mYoutubeUrl = responseJson.youTubUrl;
      this.mTwitterUrl = responseJson.twitterUrl;
      this.mSnapchatUrl = responseJson.snapchatUrl;
      this.mPinterestUrl = responseJson.pinterestUrl;
      this.mPartnerState = responseJson.state;
      this.mPartnerTheme = (responseJson.theme) ? new PartnerTheme(responseJson.theme) : null;
      this.mAppName = ""
      if(responseJson.appSettings && responseJson.appSettings.appName) {
        this.mAppName = responseJson.appSettings.appName
      }

      let pExternalDomainStatus = ExternalDomainStatus.UNKNOWN
      if(responseJson.externalDomainStatus) {
        pExternalDomainStatus = responseJson.externalDomainStatus as ExternalDomainStatus
      }
      this.mExternalDomainStatus = pExternalDomainStatus
      
      this.mPartnerCategories = []
      this.mPartnerEffects = []
      if(responseJson.categories && Array.isArray(responseJson.categories)) {
        this.mPartnerCategories = Category.partnerCategoryList(responseJson.categories)
      }
      if(responseJson.effects && Array.isArray(responseJson.effects)) {
        this.mPartnerEffects = Effect.partnerEffectList(responseJson.effects)
      }

      this.mUserPool = new PartnerUserPool(undefined)
      if(responseJson.userPoolInfo) {
        this.mUserPool = new PartnerUserPool(responseJson.userPoolInfo)
      }

      const isExternalDomainUpdated = (this.mExternalDomainName && !TextUtils.isEmpty(this.mExternalDomainName)) ? true : false
      PartnerManager.shared().setIsConnectDomainCompleted(isExternalDomainUpdated)

      this.setupPartnerAppState()
    }

    public isAnySocialMediaInfoAvailable(): boolean {
      let isAvailable = false
      if(!TextUtils.isEmpty(this.instagramUrl) || 
         !TextUtils.isEmpty(this.twitterUrl) ||
         !TextUtils.isEmpty(this.facebookUrl) ||
         !TextUtils.isEmpty(this.snapchatUrl) ||
         !TextUtils.isEmpty(this.youtubeUrl) ||
         !TextUtils.isEmpty(this.pinterestUrl)) {
          isAvailable =  true
      }
      return isAvailable
    }

    public setupPartnerAppState() {
      let pAppState = PartnerAppState.SETUP_INCOMPETE
      if(this.isSetupInCompleted()) {
          pAppState = PartnerAppState.SETUP_INCOMPETE
      } else if(this.mPartnerState === PartnerState.DRAFT) {
          pAppState = PartnerAppState.SETUP_COMPLETED
      } else {
          pAppState = PartnerAppState.LIVE
      }
      if(this.mPartnerState === PartnerState.LIVE) {
        pAppState = PartnerAppState.LIVE
      }
      this.mPartnerAppState = pAppState
    }

    public isSetupInCompleted() {
      let isSetupInCompleted = false
      if(TextUtils.isEmpty(this.mClientId) || 
         TextUtils.isEmpty(this.mClientSecret) ||
         this.isUserPoolDetailsEmpty() ||
         !PartnerManager.shared().isBrandingSetupDone ||
         !this.mPartnerTheme ||
         !this.mPartnerTheme.isThemeConfigured() ||
         (this.mPartnerTheme.isCategoryTheme() && this.mPartnerCategories.length == 0) ||
         (this.mPartnerTheme.isEffectTheme() && this.mPartnerEffects.length == 0) ||
         !PartnerManager.shared().isSocialMediaUpdated ||
         TextUtils.isEmpty(this.mDomainName) ||
         !PartnerManager.shared().isConnectDomainCompleted) {
          
          isSetupInCompleted = true
          // console.log('mClientId isEmpty:'+TextUtils.isEmpty(this.mClientId))
          // console.log('mClientSecret isEmpty:'+TextUtils.isEmpty(this.mClientSecret))
          // console.log('isBrandingSetupDone :'+PartnerManager.shared().isBrandingSetupDone)
          // console.log('mPartnerTheme :'+this.mPartnerTheme)
          // console.log('isThemeConfigured :'+this.mPartnerTheme.isThemeConfigured())
          // console.log('mPartnerCategories.length is zero:'+this.mPartnerCategories.length)
          // console.log('mPartnerEffects.length is zero:'+this.mPartnerEffects.length)
          // console.log('isSocialMediaUpdated :'+PartnerManager.shared().isSocialMediaUpdated)
          // console.log('mDomainName isEmpty:'+TextUtils.isEmpty(this.mDomainName))
          // console.log('isConnectDomainCompleted :'+PartnerManager.shared().isConnectDomainCompleted)
          // console.log('is IN-COMPLETE :'+isSetupInCompleted)
      }
      return isSetupInCompleted
    }

    public isUserPoolDetailsEmpty() {
      if(TextUtils.isEmpty(this.mUserPool.region) || 
        TextUtils.isEmpty(this.mUserPool.userPoolId) ||
        TextUtils.isEmpty(this.mUserPool.appClientId)) {
          return true
        }
        return false
    }

}