import { Box, Divider, Grid, TextField } from "@mui/material";
import React, { useState } from "react";

interface IProps {
  backgroundColor: string;
  setBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
}
const PageBg = (props: IProps) => {
  const { backgroundColor, setBackgroundColor } = props;
  return (
    <Box className="colorPicker2">
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <input
            id="txtRadius"
            className="borderGray"
            value={backgroundColor}
            type="color"
            onChange={(e) => setBackgroundColor(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={6} lg={4}>
          <Box>
            <div style={{ marginTop: "20px" }}>
              <input
                className="inputMargin"
                placeholder={backgroundColor}
                onChange={(e) => setBackgroundColor(e.target.value)}
                type="text"
                value={backgroundColor}
              />
              <Box className="hex">Hex Color (3 or 6 digits)</Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PageBg;
{
  /* <Box className="colorPickerTxt">{backgroundColor} </Box> */
}
{
  /* <Divider style={{ backgroundColor: "#000" }} /> */
}
{
  /* <TextField
                id="outlined-helperText"
                value={favUrl}
                onChange={(e) => setFavUrl(e.target.value)}
                fullWidth
                defaultValue={backgroundColor}
                placeholder={backgroundColor}
                variant="standard"
                className="inputmargin"
              /> */
}
