import React, { useContext, useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import ContextService from "../../../services/ContextService";
import { IContextService } from "../../../services/IContextService";
import PartnerManager from "../../../core/utils/PartnerManager";
import Effect1Themes from "./previewThemes/PreviewThemes";
import { getCategoryList, getEffectsList } from "../../../services/Constant";
import PartnerTheme from '../../../core/models/PartnerTheme';
import PartnerService from '../../../services/PartnerService';
import { PartnerAppTheme, PartnerAppThemeType } from '../../../core/models/PartnerTheme';
import { ApiError } from "../../../core/webservice/ApiError";
import Strings from "../../../core/utils/Strings";
declare var $: any;

interface IProps {
  pThemesList: PartnerTheme[],
  pActiveTheme: PartnerTheme
  onThemeUpdated(theme: PartnerTheme): void;
}

const PreviewThemes = (props: IProps): JSX.Element => {
  const sPartner = PartnerManager.shared().partner
  const context: IContextService = useContext(ContextService);
  const [selectedThemeType, setSelectedThemeType] = useState<PartnerAppThemeType>(props.pActiveTheme.themeType);
  const [previewEffect, setPreviewEffect] = useState<boolean>(false);
  const [previewValue, setpreviewValue] = useState({
    uiLevel: "",
    backEndLevel: "",
  });
  const [setupCompleted, setSetupCompleted] = useState<boolean>((sPartner.theme) ? true : false);

  useEffect(() => {
    // display selected theme border box
    switch (selectedThemeType) {
      case PartnerAppThemeType.CATEGORIES:
          onCategoryThemeSelected();
          break;
      case PartnerAppThemeType.EFFECTS_V2:
          onEffectThemeSelected();
          break;
      default:
          onEffectThemeSelected();
          break;
    }
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      context.setPanel(newExpanded ? panel : false);
  };

  const handleNextClicked = (): void => {
    let themeParams: PartnerAppTheme = undefined
    if(sPartner.theme && sPartner.theme.themeType !== selectedThemeType) {
      // previously created but choosing new theme.
      // Action - create new theme by inheriting current theme parameters
      const currentTheme = PartnerManager.shared().partner.theme
      const newTheme = PartnerTheme.defaultPartnerTheme(selectedThemeType)
      themeParams = {
        name: selectedThemeType,
        logoUrl: currentTheme.logoUrl,
        faviconUrl: currentTheme.favIconUrl,
        backgroundColor: newTheme.backgroundColor,
        pageHighlightColor: newTheme.pageHighlightColor,
        buttonColor: newTheme.buttonColor,
        buttonTextColor: newTheme.buttonTextColor,
      }
    } else if(!sPartner.theme){
      // Create new theme
      // Action - new theme with default color should be created.
      const defaultThemeObj = PartnerTheme.defaultPartnerTheme(selectedThemeType)
      themeParams = {
        name: selectedThemeType,
        backgroundColor: defaultThemeObj.backgroundColor,
        pageHighlightColor: defaultThemeObj.pageHighlightColor,
        buttonColor: defaultThemeObj.buttonColor,
        buttonTextColor: defaultThemeObj.buttonTextColor,
      }
    }

    if(themeParams) {
      PartnerService.createOrUpdateTheme(themeParams)
        .then((result: PartnerTheme) => {
          onThemeSaved(result)
        })
        .catch((error: ApiError) => {

        });
    } else {
      onThemeSaved(props.pActiveTheme)
    }    
  };

  const onThemeSaved = (result: PartnerTheme):void => {
    props.onThemeUpdated(result)
    setSetupCompleted(true);
    context.setPanel("panel3");
    // fetch the categories or effects
    if(result.isCategoryBased()) {
      getCategoryList();
    } else {
      getEffectsList();
    }
  }

  const onCategoryThemeSelected = () => {
    $(".categoryImg").addClass("img");
    $(".effectImg.img").removeClass("img");
    $(".effectImg1.img").removeClass("img");
    $(".prea").css({ "text-decoration": "none" });
  };

  const onEffectThemeSelected = () => {
    $(".effectImg").addClass("img");
    $(".categoryImg.img").removeClass("img");
    $(".effectImg1.img").removeClass("img");
    $(".prea").css({ "text-decoration": "none" });
  };

  
  const setRadio = (e: any): void => {
    setSelectedThemeType(e.target.value)
  };

  const PreviewEffect1 = () => {
    setPreviewEffect(true);
    setpreviewValue({ uiLevel: "Effects 1 Theme", backEndLevel: "EFFECTS_V2" });
  };
   
  const PreviewCategories = () => {
    setPreviewEffect(true);
    setpreviewValue({
      uiLevel: "Categories Theme",
      backEndLevel: "CATEGORIES",
    });
  };

  return (
    <>
      <Effect1Themes
        previewEffect={previewEffect}
        setPreviewEffect={setPreviewEffect}
        previewValue={previewValue}
        setpreviewValue={setpreviewValue}
      />

      <Accordion
        className="accordion_border border_none"
        expanded={context.panel === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          sx={{ mt: 2 }}
          expandIcon={<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="reduce_height"
        >
          <Typography className="acc_header">
            {setupCompleted && (
              <img
                src="../assets/images/main/check.png"
                className="iconCheck"
              />
            )}
           {Strings.CHOOSE_THEME}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" justifyContent="center">
            <Grid container item md={10} spacing={5}>
              <div onChange={(e) => setRadio(e)}>
                <input
                  type="radio"
                  value="CATEGORIES"
                  name="themes_name"
                  id="themes_name_catagories"
                  className="radio-test_1"
                />
                <input
                  type="radio"
                  value="EFFECTS_V2"
                  name="themes_name"
                  id="themes_id_effect1"
                  className="radio-test_1"
                />
                <input
                  type="radio"
                  value="EFFECTS"
                  name="themes_name"
                  id="themes_id_effect2"
                  className="radio-test_1"
                />
              </div>

              <Grid item textAlign="center" md={6} sm={6} lg={6}>
                <label
                  htmlFor="themes_name_catagories"
                  id="label_categories"
                  onClick={onCategoryThemeSelected}
                  className="label categories_txt"
                >
                  <div className="categoryImg">
                    <Typography className="head-title">Categories</Typography>
                    <Box className="themes_grid">
                      <img src="../assets/images/1.png" />

                      <Box className="choosePre" onClick={PreviewCategories}>
                        preview
                      </Box>
                    </Box>
                    <Typography className="prea store_prea">
                      A store layout organized by product categories: Flower,
                      Edibles, Gear, etc.{" "}
                    </Typography>
                  </div>
                </label>
              </Grid>

              <Grid item md={6} sm={6} lg={6}>
                <label
                  htmlFor="themes_id_effect1"
                  id="label_effects"
                  onClick={onEffectThemeSelected}
                  className="label effects_txt"
                >
                  <div className="effectImg">
                    <Typography className="head-title">Effects</Typography>
                    <Box className="themes_grid">
                      <img src="../assets/images/2.png" />
                    </Box>
                    <Box className="choosePre" onClick={PreviewEffect1}>
                      preview
                    </Box>
                    <Typography className="prea store_prea">
                      An alternate store layout organized around the effects of
                      the product: Energetic, Creative, Sleepy, etc.
                    </Typography>
                  </div>
                </label>
              </Grid>
            </Grid>
        
          </Grid>
          <Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 8 }}
          />
          <Grid textAlign="left" item md={12}>
            <Button
              className="setup_btn account-setup"
              sx={{ mt: 2, mb: 2 }}
              variant="text"
              onClick={handleNextClicked}
            >
              {Strings.NEXT_BUTTON_TITLE}
              <Box className="arrowBox">
                <img
                  className="arrowbtn"
                  src="../assets/images/main/arrow.png"
                />
              </Box>
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PreviewThemes;
