import React,{useState} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid } from '@mui/material';
import {CopyToClipboard} from "react-copy-to-clipboard";
declare var $: any;
export default function SeeDetailsDomain() {
const [value, setValue] = useState(process.env.REACT_APP_INTERNAL_IP_ADDRESS);
const [copied,setCopied] = useState(false);
const [show,setShow] = useState(false)

const copyicon=()=>{
setTimeout(()=>{setCopied(false)},3000);
setCopied(true)
}
  return (
    <>
     <Accordion className='bgNoneAcc'>
        <AccordionSummary className='icon_accordian'
          expandIcon={<ExpandMoreIcon />} onClick={()=>setShow(!show)}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
<Typography className="connectDomain"><p>To connect your domain, follow the instructions to change your</p><p> provider account settings.</p></Typography>          
{
show?<Typography variant="h6" className="connectShow connectLbl">close</Typography> : <Typography variant="h6" className="connectdetails connectLbl">SEE details</Typography> 
}         

</AccordionSummary>
       
        <AccordionDetails>
          <Typography className='see_Pre'>
          <p>Please visit your network provider dashboard and update following values.</p>
</Typography>
<Grid className='domainRecord' sx={{mt:5}} container maxWidth="lg" md={6}>

<Typography variant="h6" className="connectLbl">
A Record [@] Value
</Typography>
<Typography sx={{mt:1}}>

<input value={value} className="no-outline" onChange={({target:{value}})=>setValue(value)}/>

<CopyToClipboard text={value} onCopy={()=>setCopied(true)}><Button className="copyUrl">
<span className="copyicon" onClick={copyicon} ><img src="../assets/images/main/copy.png" /></span>  
  </Button></CopyToClipboard>
{
  copied ? <span className="tooltiptext"><img src="../assets/images/main/domainCheck.png" />   Copied</span>:null
}

</Typography>
</Grid>
<Grid>    
<Typography  className='reflectPre' sx={{mt:4}}>
It usually takes 24-48 hours for these changes to reflect. For any assistance, please reach out to us.
</Typography>
</Grid>
        </AccordionDetails>
      </Accordion>     
    </>
  );
}
