
export interface IStore {
    id: number;
    delivery: boolean;
    description: string,
    full_address: string;
    lat: number;
    long: number;
    medical: boolean;
    name: string;
    phone: string;
    photo: string;
    pickup: boolean;
    recreational: boolean;
    time_zone_identifier: string;
    google_map_url: string;
}

export default class Store {
    private mId: number;
    public get id() {
      return this.mId;
    }
  
    private mName: string;
    public get name() {
      return this.mName;
    }

    private mDescription: string;
    public get description() {
      return this.mDescription;
    }

    private mPhoto: string;
    public get photo() {
      return this.mPhoto;
    }
  
    private mFullAddress: string;
    public get fullAddress() {
      return this.mFullAddress;
    }

    private mPhone: string;
    public get phone() {
      return this.mPhone;
    }

    private mTimeZone: string;
    public get timezone() {
      return this.mTimeZone;
    }
  
    private mIsDelivery: boolean;
    public get isDelivery() {
      return this.mIsDelivery;
    }

    private mIsPickup: boolean;
    public get isPickup() {
      return this.mIsPickup;
    }

    private mIsMedical: boolean;
    public get isMedical() {
      return this.mIsMedical;
    }

    private mIsRecreational: boolean;
    public get isRecreational() {
      return this.mIsRecreational;
    }

    private mLatitude: number;
    public get latitude() {
      return this.mLatitude;
    }

    private mLongitude: number;
    public get longitude() {
      return this.mLongitude;
    }

    private mGoogleMapsUrl: string;
    public get googleMapsUrl() {
      return this.mGoogleMapsUrl;
    }
  
    public constructor(responseJson: IStore) {
      this.mId = responseJson.id;
      this.mName = responseJson.name;
      this.mDescription = responseJson.description;
      this.mPhoto = responseJson.photo;
      this.mFullAddress = responseJson.full_address;
      this.mPhone = responseJson.phone;
      this.mTimeZone = responseJson.time_zone_identifier;
      this.mIsDelivery = responseJson.delivery;
      this.mIsPickup = responseJson.pickup;
      this.mIsMedical = responseJson.medical;
      this.mIsRecreational = responseJson.recreational;
      this.mLatitude = responseJson.lat;
      this.mLongitude = responseJson.long;
      this.mGoogleMapsUrl = responseJson.google_map_url
    }

    public static list(arrayOfStore: any): Store[] {
      let finalArray: Store[] = [];
      for (let store of arrayOfStore) {
          let storeObj = new Store(store);
          finalArray.push(storeObj)
      }
      return finalArray
    }

    public static storeWithAlgoliaResult(response: any): Store { 
      let store: Store = new Store(response);
      return store;
    }
}