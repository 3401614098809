import * as React from 'react';
import { useContext } from "react";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PartnerManager from '../../../core/utils/PartnerManager';
import Partner, { PartnerAppState } from '../../../core/models/Partner';
import PartnerService from '../../../services/PartnerService';
import { ApiError } from '../../../core/webservice/ApiError';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IContextService } from '../../../services/IContextService';
import ContextService from '../../../services/ContextService';

declare var $: any;

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '2px solid',
  lineHeight: 1.5,
  width: '60%',
  borderColor: 'transparent',
  fontFamily: [
    'Overpass'
  ].join(','),
  '&:hover': {
    borderColor: 'rgba(255, 194, 32, 1)',
    boxShadow: 'none',
    border: '2px solid rgba(255, 194, 32, 1)',
  },
});

const BootstrapButtonLive = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '2px solid',
  lineHeight: 1.5,
  borderColor: 'rgba(0, 188, 111, 1)',
  fontFamily: [
    'Overpass'
  ].join(','),
  '&:hover': {
    borderColor: 'rgba(0, 188, 111, 1)',
    boxShadow: 'none',
    border: '2px solid rgba(0, 188, 111, 1)',
  },
});

const BootstrapButtonNotLive = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '2px solid',
  lineHeight: 1.5,
  width: '60%',
  borderColor: 'rgba(0, 0, 0, 1)',
  fontFamily: [
    'Overpass'
  ].join(','),
  '&:hover': {

    borderColor: 'rgba(0, 0, 0, 1)',
    boxShadow: 'none',
    border: '2px solid rgba(0, 0, 0, 1)',
  },
});

export default function SiteStatus(): JSX.Element {
  const status: PartnerAppState = PartnerManager.shared().partner.partnerAppState;
  const disableBtn: boolean = status == PartnerAppState.SETUP_INCOMPETE;
  const [open, setOpen] = React.useState<boolean>(false);
  const context: IContextService = useContext(ContextService);

  const GoLive = (): void => {
    PartnerService.makeGoLive()
      .then((result: Partner) => {
        context.setHeader({length:100,index:6});
        handleClickOpen();
      })
      .catch((error: ApiError) => {
        // console.log('error code:' + error.errorCode)
        // console.log('error message:' + error.message)
      });
  }

  const openInNewTab = (): void => {
    const previewUrl = PartnerManager.shared().getPartnerPreviewUrl()
    if(previewUrl) {
      window.open(previewUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setTimeout(() => {
      $(".oneDialog").show();
      $(".twoDialog").hide();
    }, 2000);
    setTimeout(function () {
      setOpen(false);
    }, 5000);
  };

  return (
    <>
      <Grid sx={{ mt: 5, mb: 5 }}>

        <Box className='siteStatus'>
          <Box className='siteStatusBox'>
            <Typography  className='siteStatus' variant='h3'>Site Status</Typography>
          </Box>


          <Stack className='stackRight' spacing={2} direction="row">
              {status == PartnerAppState.SETUP_COMPLETED && <BootstrapButton className='siteBtn siteStatusBtn' variant="outlined">
                <Typography className='siteLbl -incomplete'>
                  Setup Completed
                </Typography>
                <Brightness1Icon className='brightIcon' sx={{fill:'#FFAB2D'}}/>
              </BootstrapButton>}
              {status == PartnerAppState.LIVE && <BootstrapButtonLive className='siteBtn siteStatusBtn' variant="outlined">
                <Typography className='siteLbl -live'>
                  Live
                </Typography>


                <Brightness1Icon className='greenIcon' sx={{fill:'green'}} />
              </BootstrapButtonLive>}
              {status == PartnerAppState.SETUP_INCOMPETE && <BootstrapButtonNotLive className='siteBtn siteStatusBtn' variant="outlined">
                <Typography className='siteLbl -incomplete'>
                  Setup Incomplete
                </Typography>
                <Brightness1Icon className='brightNotLive' sx={{fill:'#FFAB2D'}}/>
              </BootstrapButtonNotLive>}
            </Stack>



        </Box>
        <br/>

        <div>

          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

              <DialogContent className='goingLive'>
              <DialogContentText id="alert-dialog-description">

                <div className="oneDialog" style={{ display: "none" }}>
                  <Typography variant="h1">Hurray!</Typography>
                  <Typography>We are  all set, go to &nbsp;<a href='#'><span style={{ color: "#00BC6F", textDecoration: "underline" }}>online store</span></a> </Typography>

                  <Box className='one_imgDialog'>
                    <img src="../assets/images/main/animation_check.gif" />
                  </Box>
                </div>


                <div className="twoDialog">
                  <Typography variant="h1">Going Live!</Typography>
                  <Typography>Please wait as we prepare your online store</Typography>


                  <Box className='AnimateBox'>
                    <Box className='two_imgDialog'>
                      <img src="../assets/images/main/animation_reload.gif" />
                    </Box>
                  </Box>
                </div>

              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>

     
      </Grid>
      <Divider className="divider" sx={{ mt: 3, mb: 3 }} />
      <Box className='siteStatusRight'>


<Button className="prev" disabled={true} onClick={() => {}}>Preview Site</Button>

{status !== PartnerAppState.LIVE ? <Button className="setup_btn theme_btn goliveBtn"
  variant="contained" disabled={disableBtn} onClick={GoLive}>Go Live</Button>:
  <Button className="setup_btn theme_btn goliveBtn"
  variant="contained" disabled={false} onClick={openInNewTab}>Publish</Button>}

</Box>
    </>
  );
}
