import ButtonText from "./colorPicker/ButtonColor/ButtonText";
import ButtonTextBg from "./colorPicker/ButtonColor/ButtonTextBg";
import PageBg from "./colorPicker/PageColors/PageBg";
import PageBgText from "./colorPicker/PageColors/PageBgText";
import UploadFavicon from "./uploadImage/UploadFavicon";
import UploadLogo from "./uploadImage/UploadLogo";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ButtonColors from "./colorPicker/ButtonColor/ButtonColor";
import { IContextService } from "../../../services/IContextService";
import ContextService from "../../../services/ContextService";
import PartnerManager from "../../../core/utils/PartnerManager";
import PartnerTheme, {
  PartnerAppTheme,
  PartnerAppThemeType,
} from "../../../core/models/PartnerTheme";
import { ApiError } from "../../../core/webservice/ApiError";
import PartnerService from "../../../services/PartnerService";
import YourAppName from "./yourAppName/YourAppName";
import Partner from "../../../core/models/Partner";
import TextUtils from "../../../core/utils/TextUtils";
import Strings from "../../../core/utils/Strings";

interface IProps {
  pActiveTheme: PartnerTheme;
  onBrandingAndLogoUpdated(theme: PartnerTheme): void;
}

const SetupBrandLogo = (props: IProps): JSX.Element => {
  const sPartner = PartnerManager.shared().partner;
  const context: IContextService = useContext(ContextService);

  const [logo, setLogo] = useState<{ name?: string }>({ name: "" });
  const [favicon, setFavicon] = useState<{ name?: string }>({ name: "" });

  const [logoUrl, setLogoUrl] = useState<string>("");
  const [favUrl, setFavUrl] = useState<string>("");

  const [errorInLogoUrl, setErrorInLogoUrl] = useState<boolean>(false);
  const [errorInFavLogoUrl, setErrorInFavLogoUrl] = useState<boolean>(false);

  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const [textColor, setTextColor] = useState<string>("");

  const [buttonColor, setButtonColor] = useState<string>("");
  const [buttonTextColor, setButtonTextColor] = useState<string>("");

  const [logoFileId, setLogoFileId] = useState<string>("");
  const [favLogoFileId, setFavLogoFileId] = useState<string>("");

  const [tickIcon, setTickIcon] = useState<boolean>(false);
  const [picPath, setPicPath] = useState<string>("");
  const [favPicPath, setFavPicPath] = useState<string>("");

  const [appName, setAppName] = useState<string>(sPartner.appName);
  const [errorAppName, setErrorAppName] = useState(undefined);

  const resetColors = (): void => {
    const themeObj = PartnerTheme.defaultPartnerTheme(
      props.pActiveTheme.themeType
    );
    if (themeObj) {
      setBackgroundColor(themeObj.backgroundColor);
      setTextColor(themeObj.pageHighlightColor);
      setButtonColor(themeObj.buttonColor);
      setButtonTextColor(themeObj.buttonTextColor);
    }
  };

  useEffect(() => {
    setTickIcon(props.pActiveTheme.isThemeConfigured());
    if (props.pActiveTheme) {
      setPicPath(props.pActiveTheme.logoUrl);
      setFavPicPath(props.pActiveTheme.favIconUrl);
      setBackgroundColor(props.pActiveTheme.backgroundColor);
      setTextColor(props.pActiveTheme.pageHighlightColor);
      setButtonColor(props.pActiveTheme.buttonColor);
      setButtonTextColor(props.pActiveTheme.buttonTextColor);
    }
  }, [props.pActiveTheme]);

  const setImg = (): void => {
    let text = logoUrl;
    text.toLowerCase();
    let result = text.includes(".png");
    if (result) {
      setPicPath(logoUrl);
      setErrorInLogoUrl(false);
    } else {
      setLogoUrl("")
      setErrorInLogoUrl(true);
    } };

  const saveImgUrl = (): void => {
    let text = favUrl;
    text.toLowerCase();
    let result = text.includes(".ico");
    if (result) {
      setFavPicPath(favUrl);
      setErrorInFavLogoUrl(false);
    } else {
      setFavUrl("")
      setErrorInFavLogoUrl(true);
    } 
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      context.setPanel(newExpanded ? panel : false);
    };

  const isDataValid = (): boolean => {
    if (
      (logoUrl || logo?.name || picPath) &&
      (favUrl || favicon?.name || favPicPath) &&
      appName.length > 0
    ) {
      return true;
    }
    return false;
  };

  const handleNextClicked = () => {
    saveAppName();
  };

  const saveAppName = () => {
    if (!appName || TextUtils.isEmpty(appName)) {
      setErrorAppName("App Name is empty.");
      return;
    }
    setErrorAppName(undefined);
    PartnerService.saveAppName(appName)
      .then((result: Partner) => {
        addOrUpdateBrandingDetails();
      })
      .catch((error: ApiError) => {
        setErrorAppName(error.message);
        scrollToAppNameField();
      });
  };

  const addOrUpdateBrandingDetails = () => {
    const partner = PartnerManager.shared().partner;
    if (!partner || (partner && !partner.theme)) {
      return;
    }

    const currentTheme = partner.theme;
    const createTheme: PartnerAppTheme = {
      name: props.pActiveTheme.themeType,
      ...(logoUrl && { logoUrl: logoUrl }),
      ...(favUrl && { faviconUrl: favUrl }),
      ...(logoFileId && { logoFileId: logoFileId }),
      ...(favLogoFileId && { faviconFileId: favLogoFileId }),
      backgroundColor: backgroundColor,
      pageHighlightColor: textColor,
      buttonColor: buttonColor,
      buttonTextColor: buttonTextColor,
    };
    createTheme["id"] = currentTheme.id;
    PartnerService.createOrUpdateTheme(createTheme)
      .then((result: PartnerTheme) => {
        if (result) {
          PartnerManager.shared().setIsBrandingSetupDone(true);
          props.onBrandingAndLogoUpdated(result);
          setTickIcon(true);
          context.setPanel("panel4");
        }
      })
      .catch((error: ApiError) => {
        // console.log("error code:" + error.errorCode);
        // console.log("error message:" + error.message);
      });
  };

  const scrollToAppNameField = () => {
    const element = document.getElementById("brand-logo-accordion");
    if (element) {
      element.scrollIntoView();
    }
  };

  return (
    <>
      <Accordion
        className="accordion_border border_none"
        expanded={context.panel === "panel3"}
        onChange={handleChange("panel3")}
        id="brand-logo-accordion"
      >
        <AccordionSummary
          sx={{ mt: 2 }}
          expandIcon={<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="reduce_height"
        >
          <Typography className="acc_header">
            {tickIcon && (
              <img
                src="../assets/images/main/check.png"
                className="iconCheck"
              />
            )}
            {Strings.SETUP_BRANDING_LOGO}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            className="acc_setup_main upload_txt_main"
            container
            maxWidth="lg"
            style={{ paddingLeft: tickIcon && "20px" }}
          >
            <Typography variant="h3" sx={{ mb: 3 }}>
              {Strings.YOUR_APP_NAME}
            </Typography>
            <YourAppName
              appName={appName}
              errorAppName={errorAppName}
              onChangeAppName={setAppName}
            />
            <Box className="acc_setup_head">
              <Typography variant="h3" sx={{ mt: 3, mb: 3 }}>
                {Strings.YOUR_LOGO}
              </Typography>
            </Box>
            <Grid
              container
              alignItems="center"
              display="flex"
              justifyContent="center"
              className="uploadlogoWrap"
            >
              <Grid item md={3}>
                <UploadLogo
                  logo={logo}
                  setLogoFileId={setLogoFileId}
                  picPath={picPath || ""}
                  setPicPath={setPicPath}
                  setLogo={setLogo}
                />
              </Grid>

              <Grid item md={2}>
                <Typography className="or" variant="h3">
                  OR
                </Typography>
              </Grid>

              <Grid item md={3}>
                <Box className="logo_Url">
                  <TextField
                    id="outlined-helperText"
                    fullWidth
                    defaultValue="Logo URL"
                    label="Logo URL"
                    value={logoUrl}
                    onChange={(e) => setLogoUrl(e.target.value)}
                    helperText="Paste the URL to your logo image (Size: 1024*1024. Allowed Format: PNG)"
                    error={errorInLogoUrl}
                    variant="standard"
                  />
                </Box>
              </Grid>

              <Grid className="acc_cred_grid" item md={2}>
                <Box className="replay">
                  <img
                    src="../assets/images/replay.png"
                    alt="replay"
                    onClick={setImg}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%" }}
              className="divider divider_brand"
              sx={{ mt: 6, mb: 1 }}
            />
            <Box className="acc_setup_head">
              <Typography variant="h3" sx={{ mt: 3, mb: 3 }}>
                {Strings.FAVICON}
              </Typography>
            </Box>
            <Grid
              container
              alignItems="center"
              display="flex"
              justifyContent="center"
              className="uploadlogoWrap"
            >
              <Grid item md={3}>
                <UploadFavicon
                  favicon={favicon}
                  setFavLogoFileId={setFavLogoFileId}
                  favPicPath={favPicPath || ""}
                  setFavPicPath={setFavPicPath}
                  setFavicon={setFavicon}
                />
              </Grid>
              <Grid item md={2}>
                <Typography className="or" variant="h3">
                  OR
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Box className="logo_Url">
                  <TextField
                    id="outlined-helperText"
                    value={favUrl}
                    label="Favicon URL"
                    onChange={(e) => setFavUrl(e.target.value)}
                    fullWidth
                    defaultValue="Logo URL"
                    helperText="Paste the URL to your Favicon image (Allowed format:ICO)"
                    variant="standard"
                    error={errorInFavLogoUrl}
                  />
                </Box>
              </Grid>
              <Grid className="acc_cred_grid" item md={2}>
                <Box className="replay">
                  <img
                    src="../assets/images/replay.png"
                    alt="replay"
                    onClick={saveImgUrl}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%" }}
              className="divider divider_brand"
              sx={{ mt: 6, mb: 1 }}
            />
            <Box className="acc_setup_head">
              <Typography variant="h3" sx={{ mt: 3, mb: 3 }}>
                {Strings.PAGE_COLORS}
              </Typography>
            </Box>
            <Grid container className="uploadlogoWrap spacewrap">
              <Grid item md={6}>
                <Grid
                  sx={{ mt: 1, mb: 1 }}
                  className="txtpicker_txt"
                  item
                  md={6}
                >
                  <Typography sx={{ mb: 3, mt: 2 }} className="hrdftrColor">
                    {Strings.PAGE_BACKGROUND_COLOR}
                  </Typography>
                </Grid>
                <PageBg
                  backgroundColor={backgroundColor}
                  setBackgroundColor={setBackgroundColor}
                />
              </Grid>
              <Grid item md={6}>
                <Grid
                  sx={{ mt: 1, mb: 1 }}
                  className="txtpicker_txt"
                  item
                  md={6}
                >
                  <Typography sx={{ mb: 3, mt: 2 }} className="hrdftrColor">
                    {Strings.PRIMARY_TEXT_COLOR}
                  </Typography>
                </Grid>

                <PageBgText textColor={textColor} setTextColor={setTextColor} />
              </Grid>
            </Grid>
            <Divider
              style={{ width: "100%" }}
              className="divider divider_brand"
              sx={{ mt: 5, mb: 5 }}
            />
            <Grid md={12}>
              <Box className="acc_setup_head">
                <Typography sx={{ mt: 3, mb: 3 }} variant="h3">
                  {Strings.BUTTON_COLORS}
                </Typography>
              </Box>

              <Grid container className="uploadlogoWrap spacewrap">
                <Grid item md={6}>
                  <Grid
                    sx={{ mt: 1, mb: 1 }}
                    className="txtpicker_txt"
                    item
                    md={6}
                  >
                    <Typography sx={{ mb: 3, mt: 2 }} className="hrdftrColor">
                      {Strings.BUTTON_COLOR}
                    </Typography>
                  </Grid>

                  <ButtonTextBg
                    buttonColor={buttonColor}
                    setButtonColor={setButtonColor}
                  />
                </Grid>

                <Grid item md={6}>
                  <Grid
                    sx={{ mt: 1, mb: 1 }}
                    className="txtpicker_txt"
                    item
                    md={6}
                  >
                    <Typography sx={{ mb: 3, mt: 2 }} className="hrdftrColor">
                      {Strings.BUTTON_TEXT_COLOR}
                    </Typography>
                  </Grid>

                  <ButtonText
                    buttonTextColor={buttonTextColor}
                    setButtonTextColor={setButtonTextColor}
                  />
                </Grid>

                <Grid item md={2} sx={{ mb: 1, mt: 8 }}>
                  <ButtonColors
                    buttonColor={buttonColor}
                    buttonTextColor={buttonTextColor}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 3 }}
          />
          <Grid textAlign="left" item md={12}>
            <Button onClick={() => resetColors()} className="resetButton">
              RESET TO THEME DEFAULT
            </Button>
            <Button
              className="setup_btn account-setup"
              sx={{ mt: 2, mb: 2 }}
              variant="text"
              onClick={handleNextClicked}
              disabled={!isDataValid()}
            >
              {Strings.NEXT_BUTTON_TITLE}
              <Box className="arrowBox">
                <img
                  className="arrowbtn"
                  src="../assets/images/main/arrow.png"
                />
              </Box>
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SetupBrandLogo;
